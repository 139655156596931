/**
 * Created by roy.vanginneken on 4/06/15.
 */

var LEAVING_MESSAGE = 'Warning: there are unsaved changes in the form';
var form = $('form.check-before-leaving');
var form_action = false;
if (form.length) {
    $(document).on('click', 'button[type="submit"],button[type="reset"],input[type="submit"],input[type="reset"]', function (e) {
        form_action = true;
    });

    form.data('serialize',form.serialize());
    $(window).bind('beforeunload', function(){
        if (!form_action) {
            if (form.serialize() != form.data('serialize')) {
                return LEAVING_MESSAGE;
            }
        }
    });
}

function pad (str, max) {
    str = str.toString();
    return str.length < max ? pad("0" + str, max) : str;
}

function datetimeStringToDate(dt_string)
{
    var bits = dt_string.split(/\D/);
    return new Date(bits[0], --bits[1], bits[2], bits[3], bits[4], bits[4]);
}

$(document).ready(function() {
    $('._subscriptionLink').change("input",function () {
        var occurrenceId = $(this).data('occurrence-index');
        var errorText = $('.subscriptionlink_error[data-occurrence-index="' + occurrenceId + '"]');
        var formGroup = errorText.parent('.form-group');
        var inputVal = $(this).val();
        errorText.hide();
        formGroup.removeClass('has-error');
        if (!validateURL(inputVal) && !validateEmail(inputVal)){
            errorText.show();
            formGroup.addClass('has-error');
        }
    });
});


function validateURL(str) {
    var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return pattern.test(str);
}

function validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}