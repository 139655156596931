import $ from 'jquery';
window.$ = window.jQuery = $;

import * as Cookies from 'js-cookie';
window.Cookies = Cookies;

import DataTable from 'datatables.net';
window.DataTable = DataTable;

import he from 'he';
window.he = he;

import moment from 'moment';
window.moment = moment;

document.addEventListener('DOMContentLoaded', async () => {
    const Alpine = await import('alpinejs');
    window.Alpine = Alpine.default;
    Alpine.default.start();
});

import { diff_match_patch } from 'diff-match-patch';
window.diff_match_patch = diff_match_patch;

import 'bootstrap';
import 'metismenu';
import 'datatables.net-bs';
import 'datatables.net-responsive';
import 'datatables.net-responsive-bs';
import 'bootstrap-fileinput';
import 'eonasdan-bootstrap-datetimepicker';
import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
import 'waypoints/lib/jquery.waypoints.min.js';
import 'jquery-countto';
import 'owl.carousel';
import 'stellar.js';
import 'jquery.easing';
import 'animate.css';
import 'bootstrap-toggle';

import './styles/app.scss'

import 'bootstrap/dist/css/bootstrap.css';
import 'metismenu/dist/metisMenu.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'datatables.net-responsive-bs/css/responsive.bootstrap.css';
import 'bootstrap-fileinput/css/fileinput.css';
import 'font-awesome/css/font-awesome.css';
import 'startbootstrap-sb-admin-2/dist/css/sb-admin-2.css';

import './js/custom/homepage'
import './js/custom/general'